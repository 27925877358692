import React from "react"
import { Link, graphql } from "gatsby"
import img_developer_london_skills_night_desk from "../images/developer-london-skills-night-desk.svg"
import img_developer_london_home_desk from "../images/developer-london-home-desk.svg"
import img_recent_project_1 from "../images/full-stack-project-42-2.png"
import img_recent_project_2 from "../images/full-stack-project-blockchain-1.png"
import img_recent_project_3 from "../images/full-stack-project-covid-1.png"
import img_deckgl_map from "../components/Globals/DeckGLMap/DeckGLMap.js"



import SEO from "../components/seo"

import TypistTitle from "../components/Globals/Typist/TypistTitle.js"
import Layout from "../components/layout"

class IndexPage extends React.Component {
  componentDidMount() {
    window.setTimeout(this.preloadImages, 3000)
  }
  preloadImages = () => {
    this.preloadImage(img_developer_london_skills_night_desk)
    this.preloadImage(img_developer_london_home_desk)
    this.preloadImage(img_recent_project_1)
    this.preloadImage(img_recent_project_2)
    this.preloadImage(img_recent_project_3)
    this.preloadImage(img_deckgl_map)
  }
  preloadImage = src => {
    if (!this._preloadedImages) this._preloadedImages = []
    let img = new Image()
    img.src = src
    this._preloadedImages.push(img)
  }

  render() {
    return (
      <>
        <SEO title="Home" />
        <div className="background_home">
          <TypistTitle
            className="main_title"
            main_title={"Building Intelligent Web Applications"}
            main_subtitle={"Tom: Full Stack developer based in Central London"}
          />
          <div className="developer-london-home-desk" />
        </div>
      </>
    )
  }
}

export default IndexPage
