import React, { Component } from "react"
import Typist from "react-typist"
import "../../../../node_modules/react-typist/dist/Typist.css"

export default class TypistTitle extends Component {
  state = {
    renderMsg: false,
  }

  onHeaderTyped = () => {
    this.setState({ renderMsg: true })
  }

  render() {
    return (
      <div className="typed_title">
        <div className="main_title">
          <Typist
            className="test"
            // cursor={{ hideWhenDone: true }}
            cursor={{ show: false }}
            avgTypingDelay={50}
            onTypingDone={this.onHeaderTyped}
          >
            <h1>{this.props.main_title}</h1>
            {/* <Typist.Backspace count={4} delay={600} /> */}
            {/* er */}
          </Typist>
        </div>

        <div className="main_subtitle">
          {this.state.renderMsg ? (
            <Typist
              className="test"
              cursor={{ show: false }}
              // cursor={{ hideWhenDone: false }}
              avgTypingDelay={45}
              startDelay={550}
            >
              <h2>{this.props.main_subtitle}</h2>
            </Typist>
          ) : null}
        </div>
      </div>
    )
  }
}
